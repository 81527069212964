export default function token(state = '', action) {
  let dataState = state;
  switch (action.type) {
    case 'SET_TOKEN':
      dataState = action.payload;
      return dataState;
    default:
      return dataState;
  }
}

import React, { useEffect, useState, useRef } from 'react';
import action from '../core/services/client';
import Slider from 'react-slick';
import moment from 'moment';
const Header8 = ({wording, language, openTabWindows}) => {
    const [article, setArticle] = useState([]);
    const [arrowh8, setArrowh8] = useState();
    const sliderh8 = useRef();
    const [page, setPage] = useState(0);
    const [countItem, seCounteItem] = useState(3);
    const [iconheh, setIconheh] = useState({
        left: '/assets/icon/left-putih.svg',
        right: '/assets/icon/right.svg',
    });
    const [loading, setLoading] = useState(true);
    const settingArticle = {
        centerMode: false,
        infinite: false,
        // centerPadding: 45,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        speed: 500,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1.18,
                slidesToScroll: 1,
              }
            },
        ]
    };
    const next = () => {
        // if (countItem === page) {
        //     console.log(countItem, 'next');
        //     console.log(page, 'next');
        //     setIconheh({
        //         left: '/assets/icon/left.svg',
        //         right: '/assets/icon/right.svg',
        //     });
        // }else{
        //     setPage(page+1);
        //     console.log(countItem, 'next');
        //     console.log(page+1, 'next');
        //     setIconheh({
        //         left: '/assets/icon/left.svg',
        //         right: '/assets/icon/right.svg',
        //     });
        //   }
          arrowh8.slickNext();
    };
    const prev = () => {
        // if (page === 0) {
        //     console.log(countItem, 'prev');
        //     console.log(page, 'prev');
        //     setIconheh({
        //         left: '/assets/icon/left.svg',
        //         right: '/assets/icon/right.svg',
        //     });
        // }else{
        //   setPage(page-1);
        //   console.log(countItem, 'next');
        //   console.log(page-1, 'next');
        //   setIconheh({
        //       left: '/assets/icon/left.svg',
        //       right: '/assets/icon/right.svg',
        //   });
        // }
        arrowh8.slickPrev();
    };

    const dateTime = (value) => {
      return moment(value).format('MMMM, DD YYYY')
    }

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const limitStringDisplay = (string, length) => {
      return string.substring(0, length) + " ...";
    }

    const getArticle = async () => {
      try {
        const response = await action.article();
        setArticle(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(true);
        console.log(error);
      }
    }
    
    useEffect(() => {
      getArticle();
      // if (page === 0) {
      //     setIconheh({
      //         left: '/assets/icon/left.svg',
      //         right: '/assets/icon/right.svg',
      //     });
      // }
      // if(page === countItem){
      //     setIconheh({
      //         left: '/assets/icon/left.svg',
      //         right: '/assets/icon/right.svg',
      //     });
      // }
    }, [])
    return (
        <div className="header-8" id="mediamassa">
          <div className="frame-left">
            <div className="frame-detail-h8">
              <div className="list-sub-title">
                <span className="success">Article</span>
              </div>
              <div className="title-h8">
                {wording(65)}
              </div>
              <div className="sub-title-h8">
                {wording(66)}
              </div>
            </div>
            <div className="pagination-h8">
              <div className="left" onClick={() => prev()}>
                <img src={'/assets/icon/left.svg'} alt="pagination" />
                {/* <img src={page === 0 ? '/assets/icon/left-putih.svg' : '/assets/icon/left.svg'} alt="pagination" /> */}
              </div>
              <div className="right" onClick={() => next()}>
                <img src={'/assets/icon/right.svg'} alt="pagination" />
              </div>
            </div>
          </div>
          <div className="frame-right">
            <div className="frame-slider-h8">
              {loading ? (
                <div className="text-center d-flex justify-content-center align-content-center w-100">
                  <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt="loading gambar" />
                </div>
              ) : (
                <Slider {...settingArticle} ref={c => setArrowh8(c)}>
                  {article.map((item) => (
                    <div className="item-h8" key={item.id}>
                      <div className="frame-img">
                        <img src={process.env.REACT_APP_PUBLIC_ASSETS+item.image} alt="berita" />
                      </div>
                      <div className="frame-detail">
                        <div className="frame-content">
                          <div className="title-item" onClick={() => openTabWindows(item.url)} title={item[`title_${language}`]}>
                            {limitStringDisplay(item[`title_${language}`], 90)}
                          </div>
                          <div className="sub-title-item">
                            <span className="publisher">{item.external_tko_tag && capitalizeFirstLetter(item.external_tko_tag.title)}</span>
                            <span className="date">{dateTime(item.created_date)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </div>
        </div>
    );
};
export default Header8;
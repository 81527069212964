import { combineReducers } from 'redux';
import language from './client/language';
import token from './client/token';
import auth from './client/auth';

export default combineReducers({
  language,
  token,
  auth,
});

import axios from 'axios';
// import { REACT_APP_BASE_MAIN_URL } from '@env';

// const APP_ENV = 'demo';
const mainInstanceUrl = process.env.REACT_APP_PUBLIC_URL;
console.log(mainInstanceUrl);
const mainInstance = axios.create({
  baseURL: mainInstanceUrl,
});

const article = () => mainInstance.get(`/tkoweb/article`);
const minibanner = () => mainInstance.get('/tkoweb/minibanner');
const newsletter = (data) => mainInstance.post('/tkoweb/newsletter', data);

export default {
  article,
  minibanner,
  newsletter,
};

import enDictionary from '../language/en.json';
import idDictionary from '../language/id.json';
import langDictionary from '../language/lang.json';

const strings = {
  en: enDictionary,
  id: idDictionary,
  global: langDictionary,
};

const wordLanguage = (word, language) => {
  const words = strings[language][word];
  return words;
};
const wordLanguageSortById = (id, language, replace = true) => {
  const words = langDictionary[id][language === 'id' ? 'ID' : 'EN'];
  return words.replace('\n', '<br />');
};
const wordLanguageSortByIdFullArray = (id) => {
  const words = langDictionary[id];
  return words;
};

export default {
  wordLanguage,
  wordLanguageSortById,
  wordLanguageSortByIdFullArray
};

import { Fragment, useState } from 'react'
import {  Link } from "react-scroll";

const SubNavbar = (props) => {
	const [isActive, setIsActive] = useState(false)
	return (
		<Fragment>
			<li className="nav-item" onClick={()=>setIsActive(!isActive)}>
				<div className="nav-link nav-item-parent">
					<span>{props.title}</span>
					<span className="nav-item-symbol">{isActive ? <>&#9652;</> : <>&#9662;</>}</span>
				</div>
			</li>
			{isActive && props.childrens.map((child, index) => {
				const { isRedirect, ...anotherChild } = child
				return (
					<li key={index} className="nav-item nav-item-child">
						{child.isRedirect === true ?
							<a className="nav-link" {...anotherChild}>
								{child.title}
							</a>
							:
							<Link className="nav-link" onClick={() => child.onClick && child.onClick()} spy={true} smooth={true} offset={-70} duration={700} to={child.to}>
								{child.title}
							</Link>
						}
					</li>
				)
			})}
		</Fragment>
	)
}

export default SubNavbar
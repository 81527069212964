/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import action from '../src/core/services/client';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import Accordion from './components/accordion';
import Navbar from './components/navbar';
import Header8 from './components/header8';
import './App.scss';
import setLanguage from './core/actions/client/language';
import lang from './handler/language';
import session from './handler/session';
import { Link, animateScroll as scroll } from "react-scroll";

const Mainapp = () => {
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const languageSet = (val) => {
    dispatch(setLanguage(val));
    session.setStorage('language', val);
  }
  const wording = (word) => {
    const words = lang.wordLanguageSortById(word, language);
    return words;
  };
  const wordingFullarray = (word) => {
    const words = lang.wordLanguageSortByIdFullArray(word);
    return words;
  };
  const [slideheh, setSlideheh] = useState({
    dots: true,
    show: 2.2,
  });
  const changeSlide = () => {
    const makeSlideheh = {
      dots: true,
      show: slideheh.show === 2.2 ? 3 : 2.2
    };
    var element = document.getElementById("frame-slidesshow-h2");
    var elementul = document.getElementById("button-expand");
    if (slideheh.show === 2.2) {
      element.classList.add("frame-lebar");
      elementul.classList.add("button-lebar");
    }else{
      element.classList.remove("frame-lebar");
      elementul.classList.remove("button-lebar");
    }
    setSlideheh(makeSlideheh);
  }
  const settings = {
    className: "center",
    centerMode: false,
    infinite: false,
    // centerPadding: 45,
    slidesToShow: slideheh.show,
    dots: slideheh.dots,
    appendDots: dots => (
      <div className="flex-dots">
        <ul id="button-expand" style={{ margin: "0px" }}> {dots} </ul>
        <button>
          <img src="/assets/icon/expand.svg" onClick={() => changeSlide()} alt="expand" />
        </button>
      </div>
    ),
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: true,
          centerPadding: "20px",
        }
      },
    ]
  };
  const settingTopHeaders = {
    className: "header-top",
    dots: false,
    infinite: true,
    autoplay: true,
    padding: 0,
    margin: 0,
    initialSlide: 0,
    slidesToShow: 1,
    speed: 500,
    // variableWidth: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
    ]
  };

  const settingsBodySwipe = {
    dots: slideheh.dots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const [menu, setMenu] = useState(0);
  const listmenu = [
    [wording(21, language), wording(22, language), '/assets/discover/0.png', null, null],
    [wording(23, language), wording(24, language), '/assets/discover/2.png', null, null],
    [wording(25, language), wording(26, language), '/assets/discover/3.png', null, null],
    [wording(27, language), wording(28, language), '/assets/discover/4.png', null, null],
    [wording(38, language), wording(39, language), '/assets/discover/5.png', 'https://mall.tokoscape.com/', <a href={wordingFullarray(130).Notes} target="_blank" rel="noreferrer" className="button-list-menu">{wordingFullarray(130)[language.toUpperCase()]}</a>],
    [wording(40, language), wording(41, language), '/assets/discover/7.png', null, null],
    [wording(42, language), wording(43, language), '/assets/discover/6.png', null, null],
  ];
  const menuList = listmenu.map((item, index) => {
    return (
      <div key={index} className={index === menu ? 'item-left-h5 active' : 'item-left-h5'} onClick={() => setMenu(index)}>
        <span>{item[0]}</span>
      </div>
    );
  });
  const[pendiri, setPendiri] = useState(0);
  const listpendiri = [
     {
       name: wording(45, language).split('<br />')[0],
       title: wording(45, language).split('<br />')[1],
       detail: wording(46, language),
       image: '/assets/founders/founders-1.png',
       mini: '/assets/founders/founders-mini-1.png',
     },
     {
       name: wording(47, language).split('<br />')[0],
       title: wording(47, language).split('<br />')[1],
       detail: wording(48, language),
       image: '/assets/founders/founders-2.png',
       mini: '/assets/founders/founders-mini-2.png',
     },
     {
       name: wording(49, language).split('<br />')[0],
       title: wording(49, language).split('<br />')[1],
       detail: wording(50, language),
       image: '/assets/founders/founders-3.png',
       mini: '/assets/founders/founders-mini-3.png',
     },
     {
       name: wording(51, language).split('<br />')[0],
       title: wording(51, language).split('<br />')[1],
       detail: wording(52, language),
       image: '/assets/founders/founders-4.png',
       mini: '/assets/founders/founders-mini-4.png',
     },
     {
       name: wording(53, language).split('<br />')[0],
       title: wording(53, language).split('<br />')[1],
       detail: wording(54, language),
       image: '/assets/founders/founders-5.png',
       mini: '/assets/founders/founders-mini-5.png',
     },
  ];
  const[komunitas, setKomunitas] = useState(0);
  const[textunduh, setTextunduh] = useState(wording(114, language));
  var d = new Date();
  var year = d.getFullYear();
  const[h3select, setH3Select] = useState(0);
  const listh3 = [
    {
      title: wording(8, language),
      desc: wording(9, language),
      cover: '/assets/h3/h3-1.jpeg',
    },
    {
      title: wording(10, language),
      desc: wording(11, language),
      cover: '/assets/h3/h3-2.jpeg',
    },
    {
      title: wording(12, language),
      desc: wording(13, language),
      cover: '/assets/h3/h3-3.jpeg',
    },
  ];
  const nextButton = () => {
    if (h3select !== 2) { 
      setH3Select(h3select+1);
    }
  };
  const prevButton = () => {
    if (h3select !== 0) { 
      setH3Select(h3select-1);
    }
  };
  const [checkingMobile, setIfMobile] = useState(false);
  useEffect(() => {
    setInterval(() => {
      var isMobile = false; //initiate as false
      // device detection
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
          || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
          isMobile = true;
      }
      setIfMobile(true);
    }, 200);
  }, []);

  const [miniBanner, setMiniBanner] = useState([]);
  const getMiniBanner = async () => {
    const response = await action.minibanner();
    setMiniBanner(response.data.data);
  }

  useEffect(() => {
    getMiniBanner();
  }, []);

  const [menuFooter, setMenuFooter] = useState({
    footer1: false,
    footer2: false,
    footer3: false,
  });
  const showHideMenu = (name) => {
    if (checkingMobile) {
      setMenuFooter({
        ...menuFooter,
        [name]: !menuFooter[name]
      });
      console.log(!menuFooter[name]);
      console.log(menuFooter);
    }
  };
  const nextButtonh5 = () => {
    console.log(menu)
    console.log(listmenu.length)
    if (menu !== listmenu.length-1) { 
      setMenu(menu+1);
    }
  };
  const prevButtonh5 = () => {
    console.log(menu)
    console.log(listmenu.length)
    if (menu !== 0) { 
      setMenu(menu-1);
    }
  };
  const nextButtonh6 = () => {
    if (pendiri !== listpendiri.length-1) { 
      setPendiri(pendiri+1);
    }
  };
  const prevButtonh6 = () => {
    if (pendiri !== 0) { 
      setPendiri(pendiri-1);
    }
  };
  const [sendmail, setSendmail] = useState('');
  const submitMessage = () => {
    let swalSet = {
      icon: 'success',
      title: 'Success',
      text: 'Registration Success',
    }
    if (sendmail === '') {
      swalSet = {
        icon: 'error',
        title: 'Oops...',
        text: 'Email is required!',
      }
    }
    Swal.fire({
      icon: swalSet.icon,
      title: swalSet.title,
      text: swalSet.text,
    });
  }
  const openTabWindows = (url) => {
    window.open(url);
  }
  const [loadingEmail, setEmailLoading] = useState(false);
  const eventHandler = async (event) => {
    try {
      setEmailLoading(true);
      event.preventDefault();
      let data = new FormData()
      data.append('email', sendmail)

      const sendData = await action.newsletter(data);
      if(sendData.data.success){
        submitMessage();
      }
      setSendmail('');
      setEmailLoading(false);
    } catch (error) {
      console.log(error);
      setEmailLoading(false);
        let swalSet = {
          icon: 'error',
          title: 'Error',
          text: 'Email Address is Invalid!',
        }
      Swal.fire({
        icon: swalSet.icon,
        title: swalSet.title,
        text: swalSet.text,
      });
    }
  }
  
  return (
    <>
      <Navbar setLang={(val) => languageSet(val)} wording={(val) => wording(val, language)} wordingFullarray={(val) => wordingFullarray(val)} />
      <div className="content-home" id="content-full">
        
        <div className="frame-slide-h1">
            <Slider {...settingTopHeaders}>
              <img alt="header" src="/assets/bg/h1.jpeg" />
              <img alt="header" src="/assets/bg/h2.jpeg" />
            </Slider>
        </div>
        <div className="header-1">
          <div className="frame-header-1">
              <div className="title-h1">
                {wording(0, language)}
              </div>
              <div className="sub-title-h1">
                {wording(1, language)}
              </div>
              <div className="button-group-h1">
                <a className="btn btn-buy" target="_blank" href={wordingFullarray(2).Notes} rel="noreferrer">{wording(2, language)}</a>
                <a className="btn btn-liteh1" target="_blank" href={wordingFullarray(3).Notes} rel="noreferrer">{wording(3, language)}</a>
                {/* <Link className="btn btn-liteh1" spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(4).Notes}>{wording(4, language)}</Link> */}
                <a className="btn btn-liteh1" target="_blank" href={wordingFullarray(4).Notes}>{wording(4, language)}</a>
              </div>
          </div>
        </div>
        <div className="header-2">
          <div className="frame-slidesshow-h2" id="frame-slidesshow-h2">
            <Slider {...settings}>
                {miniBanner.map((item, index) => (
                  <img key={index} className="img-slideshow" src={process.env.REACT_APP_PUBLIC_ASSETS+item.image} alt="" onClick={() => openTabWindows(item.url)} />
                ))}
            </Slider>
          </div>
          <div className="frame-detail-h2">
            <div className="centered-detail">
              <div className="item-detail-h2">
                <span className="title">#1</span>
                <span className="sub-title">{wording(5, language)}</span>
              </div>
              <div className="divider"></div>
              <div className="item-detail-h2">
                <span className="title">200% <span>+</span> </span>
                <span className="sub-title">{wording(6, language)}</span>
              </div>
              <div className="divider"></div>
              <div className="item-detail-h2">
                <span className="title">20X<span>+</span></span>
                <span className="sub-title">{wording(7, language)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="header-tabs">
            <Slider {...settingsBodySwipe}>
            {listh3.map((data, index) => (
              <div className="item-tablist" key={index}>
                <div className="title-item-tab" dangerouslySetInnerHTML={{
                  __html: data.title
                }} />
                <img className="img-tab" src={data.cover} alt="Img h-3" />
                <div className="desc-item-tab" dangerouslySetInnerHTML={{
                  __html: data.desc
                }} />
              </div>
            ))}
            </Slider>
        </div>
        <div className="header-3-v2" id="header-3-v2">
          <div className="frame-tab-h3v2">
            <div className={`item-tab ${h3select === 0 && 'active-tab'}`} onClick={() => setH3Select(0)} dangerouslySetInnerHTML={{
              __html: wording(8, language)
            }} />
            <div className={`item-tab ${h3select === 1 && 'active-tab'}`} onClick={() => setH3Select(1)} dangerouslySetInnerHTML={{
              __html: wording(10, language)
            }} />
            <div className={`item-tab ${h3select === 2 && 'active-tab'}`} onClick={() => setH3Select(2)} dangerouslySetInnerHTML={{
              __html: wording(12, language)
            }} />
          </div>
          <div className="content-tab">
            {listh3.map((data, index) => (
              <div key={index} className={`item-content-tab ${index === h3select && 'active-tab' }`}>
                <div className="title-item-content" dangerouslySetInnerHTML={{
                  __html: data.title
                }} />
                <img className="img-h3-mobile" src={data.cover} alt="Img h-3" />
                <div className="desc-item-content" dangerouslySetInnerHTML={{
                  __html: data.desc
                }} />
                <div className="frame-image">
                  <div className="frame-button">
                    <button onClick={() => prevButton()} className={`arrow ${h3select === 0 ? '' : 'arrow-active'}`} type="button" >
                      <img src="/assets/icon/kanan.svg" alt="kiri" />
                    </button>
                  </div> 
                  <img className="img-h3" src={data.cover} alt="Img h-3" />
                  <div className="frame-button">
                    <button onClick={() => nextButton()} className={`arrow ${h3select >= 0 && h3select < 2 ? 'arrow-active' : ''}`} >
                      <img src="/assets/icon/kiri.svg" alt="right" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="dots-content">
              <div className={`arrow left ${h3select === 0 ? '' : 'arrow-active'}`} onClick={() => prevButton()}>
                <img src="/assets/icon/kanan-mini.svg" alt="kiri" />
              </div>
              <div className={`dots ${h3select === 0 && 'dots-acitve'}`} onClick={() => setH3Select(0)} />
              <div className={`dots ${h3select === 1 && 'dots-acitve'}`} onClick={() => setH3Select(1)} />
              <div className={`dots ${h3select === 2 && 'dots-acitve'}`} onClick={() => setH3Select(2)} />
              <div className={`arrow right ${h3select >= 0 && h3select < 2 ? 'arrow-active' : ''}`} onClick={() => nextButton()}>
                <img src="/assets/icon/kiri-mini.svg" alt="kiri" />
              </div>
          </div>
        </div>
        <div className="header-4" id="indopoten">
          <div className="h4-section-1">
            <div className="title-h4s1" dangerouslySetInnerHTML={{
              __html: wording(14, language)
            }} />
            <div className="desc-h4s1" dangerouslySetInnerHTML={{
              __html: wording(15, language)
            }} />
          </div>
          <div className="h4-section-2 reverse-flex">
            <div className="frame-detail-h4s2 left">
              <div className="title-h4s2">
              {wording(16, language)}
              </div>
              <div className="desc-h4s2" dangerouslySetInnerHTML={{
                __html: wording(17, language)
              }} />
            </div>
            <div className="frame-image-h4s2">
              <img className="img-h4s2" alt="img Indonesia - Infinite Potential" src="/assets/h4s2.jpeg" />
            </div>
          </div>
          <div className="h4-section-2">
            <div className="frame-image-h4s2">
              <img className="img-h4s2" alt="Global Investors have a crucial role" src="/assets/h4s2-2.jpeg" />
            </div>
            <div className="frame-detail-h4s2 right">
              <div className="title-h4s2" dangerouslySetInnerHTML={{
                __html: wording(18, language)
              }} />
              <div className="desc-h4s2" dangerouslySetInnerHTML={{
                __html: wording(19, language)
              }} />
            </div>
          </div>
        </div>
        <div className="header-5" id="abouttko">
          <div className="title-h5">
            {wording(20, language)}
          </div>
          <div className="content-h5">
            <div className="content-left-h5">
              <div className={`arrow ${menu === 0 ? '' : 'arrow-active'}`} onClick={() => prevButtonh5()}>
                <img src="/assets/icon/kanan.svg" alt="kiri" />
              </div>
              {menuList}
              <div className={`arrow ${menu >= 0 && menu < menuList.length-1 ? 'arrow-active' : ''}`} onClick={() => nextButtonh5()}>
                <img src="/assets/icon/kiri.svg" alt="kiri" />
              </div>
            </div>
            <div className="content-right-h5">
              {listmenu[menu][3] ?
                <a target="_blank" href={listmenu[menu][3]} rel="noreferrer" className="frame-right-img-h5">
                  <img src={listmenu[menu][2]} alt="h5 1" />
                </a>
              :
                <div className="frame-right-img-h5">
                  <img src={listmenu[menu][2]} alt="h5 1" />
                </div>
              }
              <div className="frame-right-title-h5">
                {listmenu[menu][0]}
              </div>
              <div className="frame-right-desc-h5">
                {listmenu[menu][1]}
                <div className="frame-right-desc-h5-content">{listmenu[menu][4]}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-6">
          <div className="title-h6">
            {wording(44, language)}
          </div>
          <img src="/assets/icon/koma.svg" alt="message" className="koma-founders" />
          <div className="detail-founders-h6">
            <div className="frame-image">
              <div className="profil-image">
                <img src= {listpendiri[pendiri].image} alt="1" />
              </div>
              <div className="frame-detail-profil">
                <img src="/assets/bg-founders.svg" className="img-frame-title hide-on-mobile" alt="2" />
                <img src="/assets/bg-founders-mobile.svg" className="img-frame-title hide-on-desktop" alt="2" />
                <div className="frame-title-image">
                  <span className="name">{listpendiri[pendiri].name}</span>
                  <span className="title">{listpendiri[pendiri].title}</span>
                </div>
              </div>
            </div>
            <div className="frame-desc">
              <p dangerouslySetInnerHTML={{
                __html: listpendiri[pendiri].detail
              }} />
            </div>
          </div>
          <div className="list-founders-h6">
            <div className={`arrow left ${pendiri === 0 ? '' : 'arrow-active'}`} onClick={() => prevButtonh6()}>
              <img src="/assets/icon/kanan-mini.svg" alt="kiri" />
            </div>
            {listpendiri.map((data, index) => (
              <div key={index} className={`item-founders ${pendiri === index ? 'active-founders' : ''}`} key={index} onClick={() => setPendiri(index)}>
                <img className="img-founders" src={data.mini} alt="img" />
                <div className="frame-detail-f">
                  <span className="name">{data.name}</span>
                  <span className="title">{data.title}</span>
                </div>
              </div>
            ))}
            <div className={`arrow right ${pendiri >= 0 && pendiri < listpendiri.length-1 ? 'arrow-active' : ''}`} onClick={() => nextButtonh6()}>
              <img src="/assets/icon/kiri-mini.svg" alt="kiri" />
            </div>
          </div>
        </div>
        <div className="header-7">
          <div className="frame-title-h7">
            <div className="title-h7">
              {wording(55, language)}
            </div>
            <div className="sub-title-h7">
              {wording(56, language)}
            </div>
          </div>
          <div className="list-community">
            <a target="_blank" href={wordingFullarray(57).Notes} rel="noreferrer" className={`item-cummunity ${komunitas === 0 && 'active-community'}`} onClick={() => setKomunitas(0)}>
              <img className="img-cummunity" src="/assets/sosmed/tele.svg" alt="sosmed" />
              <div className="item-title">{wording(57, language)}</div>
              <div className="item-sub-title">{wording(58, language)}</div>
            </a>
            <a target="_blank" href={wordingFullarray(59).Notes} rel="noreferrer" className={`item-cummunity ${komunitas === 1 && 'active-community'}`} onClick={() => setKomunitas(1)}>
              <img className="img-cummunity" src="/assets/sosmed/tele.svg" alt="sosmed" />
              <div className="item-title">{wording(59, language)}</div>
              <div className="item-sub-title">{wording(60, language)}</div>
            </a>
            <a target="_blank" href={wordingFullarray(61).Notes} rel="noreferrer" className={`item-cummunity ${komunitas === 2 && 'active-community'}`} onClick={() => setKomunitas(2)}>
              <img className="img-cummunity" src="/assets/sosmed/twit.svg" alt="sosmed" />
              <div className="item-title">{wording(61, language)}</div>
              <div className="item-sub-title">{wording(62, language)}</div>
            </a>
            <a target="_blank" href={wordingFullarray(63).Notes} rel="noreferrer" className={`item-cummunity ${komunitas === 3 && 'active-community'}`} onClick={() => setKomunitas(3)}>
              <img className="img-cummunity" src="/assets/sosmed/global.svg" alt="sosmed" />
              <div className="item-title">{wording(63, language)}</div>
              <div className="item-sub-title">{wording(64, language)}</div>
            </a>
          </div>
          <div className="frame-bg">
            <div className="bg-drop" />
          </div>
        </div>
        <Header8 wording={(val) => wording(val, language)} language={language} openTabWindows={(val) => openTabWindows(val)} />
        <div className="header-9" id="faq">
          <div className="title-h9">
            FAQ
          </div>
          <div className="frame-faq">
            <Accordion title={wording(68, language)} desc={wording(69, language)} />
            <Accordion title={wording(70, language)}desc={wording(71, language)} />
            <Accordion title={wording(72, language)} desc={wording(73, language)} />
            <Accordion title={wording(74, language)} desc={wording(75, language)} />
            <Accordion title={wording(76, language)} desc={wording(77, language)} />
          </div>
          {/* <div className="frame-img-bg-1">
            <img src="/assets/bg/h9.svg" className="img-bg" alt="img bg" />
          </div>
          <div className="frame-img-bg-2">
            <img src="/assets/bg/h9-1.svg" className="img-bg" alt="img bg" />
          </div> */}
        </div>
        <div className="tkonews" id="newslatter">
          <img className="img-tkonews" src="/assets/robot.svg" alt="robot" />
          <div className="desc-tkonews" dangerouslySetInnerHTML={{
            __html: wording(125, language)
          }} />
          {loadingEmail ? (
            <div className="text-center d-flex justify-content-center align-content-center">
              <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" style={{ height: 40 }} alt="loading gambar" />
            </div>
          ) : (
            <form className="form-input"  method="post" onSubmit = {eventHandler}>
              <div className="frame-input">
                <input type="text" value={sendmail} onChange={(e) => setSendmail(e.target.value)} placeholder={wording(126, language)} />
              </div>
              <button className="button-news" type="submit">
                <img src="/assets/icon/send.svg" alt="send" />
              </button>
            </form>
          )}
        </div>
        <div className="footer" id="footer">
          <div className="white-doc">
            <img src="/assets/footer/1.svg" alt="footer" />
            <div className="frame-white-doc">
              <div className="title-white-doc">
                {wording(78, language)}
              </div>
              <div className="sub-title-white-doc">
                {wording(79, language)}
              </div>
              <div className="list-button-wd">
                <a href="https://www.tokocrypto.com/cdn/pdf/TKO%20Litepaper%20V2.0.pdf?utm_source=TKOLandingPage&utm_medium=textlink&utm_campaign=TKO_Launch" target="_blank" rel="noreferrer" className="btn btn-lite">{wording(80, language)}</a>
                <a className="btn btn-white" onMouseOut={() => setTextunduh(wording(81, language))} onMouseEnter={() => setTextunduh(wording(114, language))}>{textunduh}</a>
              </div>
            </div>
          </div>
          <div className="footer-frame">
            <div className="footer-menu">
              <div className="item-footer">
                <div className="title-foot" onClick={() => showHideMenu('footer1')}><span>{wording(82, language)}</span> <img src={`/assets/icon/${menuFooter['footer1'] ? 'caretdownfooter.svg' : 'caretfooter.svg'}`} alt="caret footer" /></div>
                {/* <a href={wordingFullarray(82).Notes} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer1'] && 'foot-active'}`}>
                  {wordingFullarray(82)[language.toUpperCase()]}
                </a> */}
                <a href={wordingFullarray(83).Notes} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer1'] && 'foot-active'}`}>
                  {wordingFullarray(83)[language.toUpperCase()]}
                </a>
                {/* <a href={wordingFullarray(84).Notes} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer1'] && 'foot-active'}`}>
                  {wordingFullarray(84)[language.toUpperCase()]}
                </a> */}
                <a href={wordingFullarray(85).Notes} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer1'] && 'foot-active'}`}>
                  {wordingFullarray(85)[language.toUpperCase()]}
                </a>
                <a href={wordingFullarray(86).Notes} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer1'] && 'foot-active'}`}>
                  {wordingFullarray(86)[language.toUpperCase()]}
                </a>
                <a href={wordingFullarray(87).Notes} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer1'] && 'foot-active'}`}>
                  {wordingFullarray(87)[language.toUpperCase()]}
                </a>
                <a href={wordingFullarray(88).Notes} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer1'] && 'foot-active'}`}>
                  {wordingFullarray(88)[language.toUpperCase()]}
                </a>
                <a href={wordingFullarray(127).Notes} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer1'] && 'foot-active'}`}>
                  {wordingFullarray(127)[language.toUpperCase()]}
                </a>
                <a href={wordingFullarray(128).Notes} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer1'] && 'foot-active'}`}>
                  {wordingFullarray(128)[language.toUpperCase()]}
                </a>
                <a href={wordingFullarray(129).Notes} target="_blank" rel="noreferrer" className={`item-foot without-margin ${menuFooter['footer1'] && 'foot-active'}`}>
                  {wordingFullarray(129)[language.toUpperCase()]}
                </a>
              </div>
              <div className="item-footer">
                <div className="title-foot" onClick={() => showHideMenu('footer2')}><span>{wording(89, language)}</span> <img src={`/assets/icon/${menuFooter['footer2'] ? 'caretdownfooter.svg' : 'caretfooter.svg'}`} alt="caret footer" /></div>
                <a href={language === 'id' ? 'https://support.tokocrypto.com/hc/id/articles/360004305832-Tentang-Kami' : 'https://support.tokocrypto.com/hc/en-us/articles/360004305832-Tentang-Kami'} target="_blank" rel="noreferrer" className="item-foot">
                  {wordingFullarray(90)[language.toUpperCase()]}
                </a>
                <a href={wordingFullarray(91).Notes} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer2'] && 'foot-active'}`}>
                  {wordingFullarray(91)[language.toUpperCase()]}
                </a>
                <a href={language === 'id' ? 'https://support.tokocrypto.com/hc/id/articles/360004044971-Perjanjian-Pengguna-Tokocrypto' : 'https://support.tokocrypto.com/hc/en-us/articles/360004044971-Perjanjian-Pengguna-Tokocrypto'} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer2'] && 'foot-active'}`}>
                  {wordingFullarray(92)[language.toUpperCase()]}
                </a>
                <a href={language === 'id' ? 'https://support.tokocrypto.com/hc/id/articles/360004004012-Kebijakan-Privasi' : 'https://support.tokocrypto.com/hc/en-us/articles/360004004012-Kebijakan-Privasi'} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer2'] && 'foot-active'}`}>
                  {wordingFullarray(93)[language.toUpperCase()]}
                </a>
                <a href={language === 'id' ? 'https://support.tokocrypto.com/hc/id/articles/360046385532-Daftar-di-Tokocrypto-Sekarang-ajak-Teman-dan-Dapatkan-Reward-Sebesar-75-000-BIDR-' : 'https://support.tokocrypto.com/hc/en-us/articles/360046385532-Daftar-di-Tokocrypto-Sekarang-ajak-Teman-dan-Dapatkan-Reward-Sebesar-75-000-BIDR-'} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer2'] && 'foot-active'}`}>
                  {wordingFullarray(94)[language.toUpperCase()]}
                </a>
                <a href={language === 'id' ? 'https://support.tokocrypto.com/hc/id/categories/360003084012-Pertanyaan-Sering-Ditanyakan' : 'https://support.tokocrypto.com/hc/en-us/categories/360003084012-Pertanyaan-Sering-Ditanyakan'} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer2'] && 'foot-active'}`}>
                  {wordingFullarray(95)[language.toUpperCase()]}
                </a>
                <a href={wordingFullarray(96).Notes} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer2'] && 'foot-active'}`}>
                  {wordingFullarray(96)[language.toUpperCase()]}
                </a>
                {/* <a href={wordingFullarray(97).Notes} target="_blank" rel="noreferrer" className={`item-foot without-margin ${menuFooter['footer2'] && 'foot-active'}`}>
                  {wordingFullarray(97)[language.toUpperCase()]}
                </a> */}
              </div>
              <div className="item-footer">
                <div className="title-foot" onClick={() => showHideMenu('footer3')}><span>{wording(98, language)}</span> <img src={`/assets/icon/${menuFooter['footer3'] ? 'caretdownfooter.svg' : 'caretfooter.svg'}`} alt="caret footer" /></div>
                <a href={language === 'id' ? 'https://support.tokocrypto.com/hc/id/' : 'https://support.tokocrypto.com/hc/en-us/'} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer3'] && 'foot-active'}`}>
                  {wording(99, language)}
                </a>
                <a href={language === 'id' ? 'https://support.tokocrypto.com/hc/id/requests/new' : 'https://support.tokocrypto.com/hc/en-us/requests/new'} target="_blank" rel="noreferrer" className={`item-foot ${menuFooter['footer3'] && 'foot-active'}`}>
                  {wording(100, language)}
                </a>
                <a href={wordingFullarray(101).Notes} target="_blank" rel="noreferrer" className={`item-foot without-margin ${menuFooter['footer3'] && 'foot-active'}`}>
                  {wordingFullarray(101)[language.toUpperCase()]}
                </a>
              </div>
            </div>
            <div className="footer-stayupdate">
              <div className="member-of">
                <div className="title-member">
                  {wording(108, language)}
                </div>
                <img src="/assets/footer/pedagang.png" alt="member" />
              </div>
              <div className="register-of">
                <div className="title-register">
                  {wording(115, language)}
                </div>
                <div className="list-register">
                  <img src="/assets/footer/bapebti.png" alt="member" />
                  <img src="/assets/footer/mark1.png" alt="member" />
                  <img src="/assets/footer/mark2.png" alt="member" />
                  <img src="/assets/footer/kominfo.png" alt="member" />
                </div>
              </div>
            </div>
          </div>
          <div className="footer-disc">
            <div className="title-disc">Disclaimer:</div>
            <div className="desc-disc">
              {wording(109, language)}
            </div>
          </div>
          <div className="footer-bot">
            <div className="tag">#SalamToTheMoon ©TOKOCRYPTO {year}</div>
            <div className="sosmed">
              <a href={wordingFullarray(110).Notes} target="_blank" rel="noreferrer">
                <img src="/assets/footer/sosmed/1.svg" className="icon-sosmed" alt="sosmed footer" />
              </a>
              <a href={wordingFullarray(111).Notes} target="_blank" rel="noreferrer">
                <img src="/assets/footer/sosmed/2.svg" className="icon-sosmed" alt="sosmed footer" />
              </a>
              <a href={wordingFullarray(112).Notes} target="_blank" rel="noreferrer">
                <img src="/assets/footer/sosmed/3.svg" className="icon-sosmed" alt="sosmed footer" />
              </a>
              <a href={wordingFullarray(113).Notes} target="_blank" rel="noreferrer">
                <img src="/assets/footer/sosmed/4.svg" className="icon-sosmed" alt="sosmed footer" />
              </a>
              <a href={wordingFullarray(103).Notes} target="_blank" rel="noreferrer">
                <img src="/assets/footer/sosmed/tele.svg" className="icon-sosmed" alt="sosmed footer" />
              </a>
            </div>
          </div>
        </div>
        
    </div>
    </>
  );
}

export default Mainapp;

import React, { useState, useEffect } from 'react';

const Accordion = ({ title, desc, defaultActive = false }) => {
  const [active, setActive] = useState(false);
  const checkActiveState = () => {
    setActive(!active);
  };
  useEffect(() => {
    setActive(defaultActive);
  }, [defaultActive]);
  return (
    <div className="card-faq">
      <div className="frame-faq-title" onClick={() => checkActiveState()}>
        <h4>{title}</h4>
        {active ? (
          <>
            <img src="/assets/icon/caretup.svg" alt="" />
          </>
        ) : (
          <>
            <img src="/assets/icon/caretdown.svg" alt="" />
          </>
        )}
      </div>
      {active && (
        <div className="frame-faq-desc" dangerouslySetInnerHTML={{
          __html: desc
        }} />
      )}
    </div>
  );
};
export default Accordion;
